<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" title="اختيار محظية" />
    <div class="convention-item">
      <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab title="جميل .">
          <div class="card">
            هذا المنبر هو منبر التاريخ الوطني الذي يربط بين الشركاء الجنسيين . لضمان خصوصية كل مستخدم ، يمكن للعملاء المشاركة فقط عن طريق الاتصال مكتب الاستقبال أو عرض الاسم الحقيقي من كبار أعضاء منصة .</div>
          <div class="address">
            <van-row
              type="flex"
              justify=""
              gutter="65"
              v-for="(v, key) in list"
              :key="key"
            >
              <van-col
                span=""
                offset=""
                @click="addgo(v)"
                style="width: 100%"
                >
                <p class="addname">{{ v.text }}</p>
                <img style="width: 100%; height 50px" :src="v.img" alt="" />
                </van-col
              >
            </van-row>
          </div>
        </van-tab>
        <van-tab title="Lịch sử">
          <div class="card">
            هذه المنصة محاطة + المرافقة التجارية + العاطفة
في نفس المدينة ، من خلال شبكة كاملة ، وضمان
لكل مستخدم الحق في الخصوصية ، يمكن للعميل المشاركة فقط
عن طريق الاتصال موظف استقبال أو إدخال الاسم الحقيقي للمدينة
عضو أقدم في المؤسسة .
          </div>
          <div class="rig-box">
            <p class="rig-title">الموارد المتاحة?</p>
            <p class="rig-content">
              الأحمر ، نموذج ، مضيفة ، دور الشباب ، طلاب الجامعات ، فقط الأشياء التي كنت لا تتوقع . لا يمكنك أن تفعل ذلك من دون هذا الأساس .
            </p>
            <p class="rig-title">منطقة الخدمة?</p>
            <p class="rig-content">
              يمكن ترتيب الحجز المجاني في نفس المدينة ، في أي مكان في البلاد ، من الدرجة الأولى و الدرجة الثالثة في مدينة فيتنام عن طريق الاتصال مكتب الاستقبال .
            </p>
            <p class="rig-content">
              هذا المنبر هو منبر التاريخ الوطني الذي يربط بين الشركاء الجنسيين . لضمان خصوصية كل مستخدم ، يمكن للعملاء المشاركة فقط عن طريق الاتصال مكتب الاستقبال أو عرض الاسم الحقيقي من كبار أعضاء منصة .</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import p1 from "../../assets/img/1.png"
import p2 from "../../assets/img/2.png"
import p3 from "../../assets/img/3.png"
import p4 from "../../assets/img/4.png"
import p5 from "../../assets/img/5.png"
import p6 from "../../assets/img/6.png"
import p7 from "../../assets/img/7.png"
import p8 from "../../assets/img/8.png"
import p9 from "../../assets/img/9.png"
import p10 from "../../assets/img/10.png"
import p11 from "../../assets/img/11.png"
import p12 from "../../assets/img/12.png"
import p13 from "../../assets/img/13.png"
export default {
  data() {
    return {
      list: [
        {
          img: p1,
          text: "A001",
          id: 1
        },
        {
          img: p2,
          text: "B001",
          id: 2
        },
        {
          img: p3,
          text: "C001",
          id: 3
        },
        {
          img: p4,
          text: "D001",
          id: 4
        },
        {
          img: p5,
          text: "E001",
          id: 5
        },
        {
          img: p6,
          text: "F001",
          id: 6
        },
        {
          img: p7,
          text: "G001",
          id: 7
        },
        {
          img: p8,
          text: "H001",
          id: 8
        },
        {
          img: p9,
          text: "I001",
          id: 9
        },
        {
          img: p10,
          text: "J001",
          id: 10
        },
        {
          img: p11,
          text: "K001",
          id: 11
        },
        {
          img: p12,
          text: "L001",
          id: 12
        },
        {
          img: p13,
          text: "M001",
          id: 13
        },
        
      ],
      addlist: [
        {
         0 :'Beijing',
         1:'Shanghai',
         2:'Guangzhou',
         3: 'Shenzhen',
        },
        {
          0: "Nanjing",
          1: "School",
          2: "Chongqing",
          3: "Xi'an",
        },
      ],
    };
  },
  methods: {
    addgo(data) {
      this.$router.push({ path: '/profile?id=' + data.id });
      // if (!localStorage.getItem("token")) {
      //   this.$router.push({ path: "/Login" });
      // } else {
      //   this.$router.push({
      //     path: "/list?id=" + data.id + "&name=" + data.name,
      //   });
      // }
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "address_list",
      }).then((res) => {
        this.addlist = res.data;
        res['data'][0].forEach((item, index) => {
          this.getxuanfeilist(item.id, index)
        })
      });
    },
    getxuanfeilist(id, index) {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: {id}
			}).then(res => {
        this.$set(this.addlist[0][index], 'img', res.data)
        console.log(this.addlist)
			});
		}
  },
  created() {
    this.getAddress();
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-tab__text {
  color: #c24491;
}
::v-deep .van-tabs__line {
  background-color: #c24491 !important
}
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(90deg,#775fd9,#c24491);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}
.addname {
  text-align: center;
  transform: translateY(30px);
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}
::v-deep .van-tabs__line {
  background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.card {
  background-color: #775fd9;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 1rem;
  margin: 0.625rem auto;
  line-height: 1.5rem;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  // height: 80px;
  width: 100%;
  line-height: 80px;
}
::v-deep .van-col {
  // height: 80px;
  width: 100%;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #c24491;
  font-size: 1.425rem;
}
.rig-content {
  font-size: 30px;
  color: #c24491;
  // margin-top: 10px;
}
::v-deep .van-col {
  color: #c24491;
}
.address {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 110px;
  box-sizing: border-box;
}
</style>
